import React from "react";
import { Link } from "gatsby";

import TK12Styles from "./tssK12.module.css";
import Head from "../../components/head";
import headlineStyles from "../../components/headline.module.css";
import HeroHeaderStyles from "../../components/HeroHeader.module.css";

import { graphql } from "gatsby";

export const query = graphql`
  query {
    allContentfulTssK12Page {
      edges {
        node {
          headerImage {
            file {
              url
            }
          }
          headerHeadline {
            headerHeadline
          }
          headerSubheadline {
            headerSubheadline
          }
          section1Headline {
            section1Headline
          }
          section1Paragraph {
            section1Paragraph
          }
        }
      }
    }
  }
`;
class TssK12 extends React.Component {
  componentDidMount() {
    // Array.from(document.querySelectorAll("p")).forEach(el => {
    //   let v = el.innerHTML;
    //   let p1 = v.replace(
    //     "https://franklinpond.org",
    //     "<a href='https://franklinpond.org' target='_blank'>https://franklinpond.org</a>"
    //   );
    //   el.innerHTML = p1;
    // });

    Array.from(document.querySelectorAll("p")).forEach(el => {
      let v = el.innerHTML;
      // var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      let link = v.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + "</a>";
      });
      el.innerHTML = link;
    });
  }
  render() {
    return (
      <>
        <Head title="TSS training K-12"></Head>
        <section>
          <div
            className={HeroHeaderStyles.backgroundImageContainer}
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${this.props.data.allContentfulTssK12Page.edges[0].node.headerImage.file.url})`,
            }}
          >
            <div className={HeroHeaderStyles.heroContainer}>
              <h1 className={HeroHeaderStyles.heading}>
                {
                  this.props.data.allContentfulTssK12Page.edges[0].node
                    .headerHeadline.headerHeadline
                }
              </h1>
              <p className={HeroHeaderStyles.subHeading}>
                {
                  this.props.data.allContentfulTssK12Page.edges[0].node
                    .headerSubheadline.headerSubheadline
                }
              </p>
              <div className={HeroHeaderStyles.buttonContainer}>
                <Link to="/donate" className={HeroHeaderStyles.primaryBtn}>
                  Help Us
                </Link>
              </div>
            </div>
          </div>
        </section>
        <div
          className={headlineStyles.headline}
          style={{ backgroundColor: "#fff" }}
        >
          <h2>
            {
              this.props.data.allContentfulTssK12Page.edges[0].node
                .section1Headline.section1Headline
            }
          </h2>
        </div>

        <div className={TK12Styles.tk12copyContainer}>
          <div>
            <p style={{ whiteSpace: "pre-wrap" }}>
              {
                this.props.data.allContentfulTssK12Page.edges[0].node
                  .section1Paragraph.section1Paragraph
              }
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Link
                to="/donate"
                style={{
                  textDecoration: "none",
                  color: "#ba0c2f",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                Help us
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default TssK12;
